"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.viewItemListEvent = exports.selectItemEvent = exports.viewItemEvent = exports.purchaseEvent = exports.addShippingInfoEvent = exports.commonDataLayerEvent = void 0;
const types_1 = require("@/DataLayer/types");
const helpers_1 = require("@/DataLayer/helpers");
const commonDataLayerEvent = (itemsArray, eventName, listName) => {
    var _a, _b, _c;
    const { value, currency } = (0, helpers_1.calcCurrencyAndValue)(itemsArray);
    (0, helpers_1.pushEvent)({
        event: eventName,
        ecommerce: {
            currency: ((_c = (_b = (_a = itemsArray[0]) === null || _a === void 0 ? void 0 : _a.prices) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.currency) || currency,
            value,
            items: (0, helpers_1.formItemsArray)(itemsArray, listName, false, true)
        }
    });
};
exports.commonDataLayerEvent = commonDataLayerEvent;
const addShippingInfoEvent = (itemsArray, shipping_tier = '', listName) => {
    const { value, currency } = (0, helpers_1.calcCurrencyAndValue)(itemsArray);
    (0, helpers_1.pushEvent)({
        event: types_1.EDataLayerEventsNames.ADD_SHIPPING_INFO,
        ecommerce: {
            currency,
            value,
            shipping_tier,
            items: (0, helpers_1.formItemsArray)(itemsArray, listName)
        }
    });
};
exports.addShippingInfoEvent = addShippingInfoEvent;
const purchaseEvent = (itemsArray, shipping_tier = '', shipping_address = '', shipping = 0, tax = 0, transaction_id = '', listName) => {
    const { value, currency } = (0, helpers_1.calcCurrencyAndValue)(itemsArray);
    (0, helpers_1.pushEvent)({
        event: types_1.EDataLayerEventsNames.PURCHASE,
        ecommerce: {
            currency,
            value,
            shipping_tier,
            shipping_address,
            shipping,
            tax,
            transaction_id,
            items: (0, helpers_1.formItemsArray)(itemsArray, listName)
        }
    });
};
exports.purchaseEvent = purchaseEvent;
const viewItemEvent = (itemsArray, listName) => {
    const { value, currency } = (0, helpers_1.calcCurrencyAndValue)(itemsArray, true);
    (0, helpers_1.pushEvent)({
        event: types_1.EDataLayerEventsNames.VIEW_ITEM,
        ecommerce: {
            currency: currency,
            value: value,
            items: (0, helpers_1.formItemsArray)(itemsArray, listName, true)
        }
    });
};
exports.viewItemEvent = viewItemEvent;
const selectItemEvent = (itemsArray, listName) => {
    var _a, _b, _c, _d, _e, _f;
    const currency = ((_c = (_b = (_a = itemsArray === null || itemsArray === void 0 ? void 0 : itemsArray[0]) === null || _a === void 0 ? void 0 : _a.prices) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.currency) || null;
    const value = ((_f = (_e = (_d = itemsArray === null || itemsArray === void 0 ? void 0 : itemsArray[0]) === null || _d === void 0 ? void 0 : _d.prices) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.itemPrice) || null;
    const items = (0, helpers_1.formItemsArray)(itemsArray, listName);
    if (items.length > 0) {
        items[0] = Object.assign(Object.assign({}, items[0]), { price: value });
    }
    (0, helpers_1.pushEvent)({
        event: types_1.EDataLayerEventsNames.SELECT_ITEM,
        ecommerce: {
            currency,
            value,
            items
        }
    });
};
exports.selectItemEvent = selectItemEvent;
const viewItemListEvent = (itemsArray, listName) => {
    (0, helpers_1.pushEvent)({
        event: types_1.EDataLayerEventsNames.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_name: listName,
            items: (0, helpers_1.formItemsArray)(itemsArray, listName, false, true)
        }
    });
};
exports.viewItemListEvent = viewItemListEvent;
