"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcCurrencyAndValue = exports.formItemsArray = exports.formDataLayerObject = exports.calcStock = exports.calcPriceAndQuantity = exports.formCategories = exports.isRevertedBreadcrumbs = exports.isNumber = exports.isString = exports.filterDuplicatesFromArray = exports.sortByLowerCaseLetter = exports.pushEvent = exports.convertStringPriceToNumber = void 0;
const constants_1 = require("@/DataLayer/constants");
const convertStringPriceToNumber = (str) => {
    const parts = str.split(' ');
    if (parts.length > 1) {
        return +parts[1].replace(/,/g, '');
    }
    return 0;
};
exports.convertStringPriceToNumber = convertStringPriceToNumber;
const pushEvent = (data) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ ecommerce: null });
    dataLayer.push(data);
};
exports.pushEvent = pushEvent;
const sortByLowerCaseLetter = (arr) => arr.sort((a, b) => {
    const firstCharA = a.charAt(0);
    const firstCharB = b.charAt(0);
    if (firstCharA === firstCharA.toLowerCase() && firstCharB === firstCharB.toUpperCase()) {
        return -1;
    }
    if (firstCharA === firstCharA.toUpperCase() && firstCharB === firstCharB.toLowerCase()) {
        return 1;
    }
});
exports.sortByLowerCaseLetter = sortByLowerCaseLetter;
const filterDuplicatesFromArray = (arr) => arr.reduce((acc, item) => {
    return acc.includes(item) ? acc : [...acc, item];
}, []);
exports.filterDuplicatesFromArray = filterDuplicatesFromArray;
const isString = (variable) => typeof variable === 'string';
exports.isString = isString;
const isNumber = (variable) => typeof variable === 'number';
exports.isNumber = isNumber;
const isRevertedBreadcrumbs = (listName) => listName === 'Search Page' || listName === 'Product Listing Page' || listName === 'Product Compare Page';
exports.isRevertedBreadcrumbs = isRevertedBreadcrumbs;
const formCategories = (breadcrumbs, listName, checkBreadcrumbs) => {
    const formedCategories = checkBreadcrumbs && (0, exports.isRevertedBreadcrumbs)(listName) ? breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.slice().reverse() : breadcrumbs;
    let categories = (breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.length) ? formedCategories : [];
    categories = categories === null || categories === void 0 ? void 0 : categories.slice(2, (categories === null || categories === void 0 ? void 0 : categories.length) - 1);
    return Array.from({ length: 5 }, (_, i) => {
        var _a, _b;
        return ({
            [`item_category${i === 0 ? '' : i + 1}`]: ((_a = categories === null || categories === void 0 ? void 0 : categories[i]) === null || _a === void 0 ? void 0 : _a.key) || ((_b = categories === null || categories === void 0 ? void 0 : categories[i]) === null || _b === void 0 ? void 0 : _b.text) || null
        });
    }).reduce((acc, curr) => (Object.assign(Object.assign({}, acc), curr)), {});
};
exports.formCategories = formCategories;
const calcPriceAndQuantity = (initialPrice, prices, quantity, amount) => {
    var _a;
    const calculatedQuantity = quantity || amount || 1;
    let finalPrice;
    let calculatedPrice;
    if (typeof initialPrice !== 'number') {
        calculatedPrice = typeof initialPrice === 'string' ? (0, exports.convertStringPriceToNumber)(initialPrice) : initialPrice === null || initialPrice === void 0 ? void 0 : initialPrice.amount;
    }
    finalPrice = ((_a = prices === null || prices === void 0 ? void 0 : prices[0]) === null || _a === void 0 ? void 0 : _a.itemPrice) || calculatedPrice || null;
    return {
        price: finalPrice,
        quantity: calculatedQuantity
    };
};
exports.calcPriceAndQuantity = calcPriceAndQuantity;
const calcStock = (stockStatus, stocks, isViewItemEvent) => {
    var _a;
    const stockMessage = (_a = stockStatus === null || stockStatus === void 0 ? void 0 : stockStatus[0]) === null || _a === void 0 ? void 0 : _a.message;
    const stockAmount = stockMessage === null || stockMessage === void 0 ? void 0 : stockMessage.split(' ')[0];
    const stock = isViewItemEvent ? +stockAmount : stockAmount;
    const stockNumber = stockMessage ? +stock : 0;
    const inStockAmount = (0, exports.isNumber)(stockNumber) ? stockNumber : 0;
    const availableAmount = stocks === null || stocks === void 0 ? void 0 : stocks.reduce((acc, curr) => acc + curr.availableAmount, 0);
    const nr_in_stock = availableAmount || inStockAmount || 0;
    const stock_status = nr_in_stock ? constants_1.stockValues.IN_STOCK : constants_1.stockValues.NOT_IN_STOCK;
    return {
        nr_in_stock,
        stock_status
    };
};
exports.calcStock = calcStock;
const formDataLayerObject = (breadcrumbs, initialPrice, stockStatus, isViewItemEvent, initialQuantity, initialAmount, partNumber, productId, productLineLogo, productLine, stocks, productName, listIndex, idx, productBrand, itemListName, prices, listName, productionStatusCode, customerSpecificCode, brand, checkBreadcrumbs) => {
    var _a;
    const { item_category, item_category2, item_category3, item_category4, item_category5 } = (0, exports.formCategories)((breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.length) ? [...breadcrumbs] : [], listName, checkBreadcrumbs);
    const { price, quantity } = (0, exports.calcPriceAndQuantity)(initialPrice, prices, initialQuantity, initialAmount);
    const { nr_in_stock, stock_status } = (0, exports.calcStock)(stockStatus, stocks, isViewItemEvent);
    const item_id = partNumber || productId;
    const itemBrand1 = productBrand || brand || '';
    const itemBrand2 = productLineLogo || '';
    const itemBrand3 = productLine || '';
    const brandsArray = [itemBrand1, itemBrand2, itemBrand3];
    const nonEmptyBrandsArray = brandsArray.filter(item => item !== '');
    const uniqueBrandsArray = (0, exports.filterDuplicatesFromArray)(nonEmptyBrandsArray);
    const sortedBrandsArray = (0, exports.sortByLowerCaseLetter)(uniqueBrandsArray);
    const item_brand = sortedBrandsArray.join(' - ');
    return {
        item_id,
        item_name: productName === null || productName === void 0 ? void 0 : productName.concat(' / ', item_id),
        index: listIndex || idx || 0,
        item_brand,
        item_category,
        item_category2,
        item_category3,
        item_category4,
        item_category5,
        item_list_name: listName || itemListName || null,
        price,
        quantity,
        discount: ((_a = prices === null || prices === void 0 ? void 0 : prices[0]) === null || _a === void 0 ? void 0 : _a.discount) || 0,
        customer_specific: constants_1.customerSpecificArray[customerSpecificCode] || null,
        nr_in_stock,
        stock_status,
        item_status: constants_1.productionStatusArray[productionStatusCode] || null
    };
};
exports.formDataLayerObject = formDataLayerObject;
const formItemsArray = (itemsArray, listName, isViewItemEvent = false, checkBreadcrumbs = false) => itemsArray.map((item, idx) => {
    const { breadcrumbs, price: initialPrice, stockStatus, quantity: initialQuantity, amount: initialAmount, partNumber, productId, productLineLogo, productLine, stocks, productName, listIndex, productBrand, listName: itemListName, prices, productionStatusCode, customerSpecificCode, brand } = item;
    return Object.assign({}, (0, exports.formDataLayerObject)(breadcrumbs, initialPrice, stockStatus, isViewItemEvent, initialQuantity, initialAmount, partNumber, productId, productLineLogo, productLine, stocks, productName, listIndex, idx, productBrand, itemListName, prices, listName, productionStatusCode, customerSpecificCode, brand, checkBreadcrumbs));
});
exports.formItemsArray = formItemsArray;
const calcCurrencyAndValue = (itemsArray, isViewItemEvent = false) => {
    var _a;
    const price = (_a = itemsArray === null || itemsArray === void 0 ? void 0 : itemsArray[0]) === null || _a === void 0 ? void 0 : _a.price;
    const isPriceString = typeof price === 'string';
    let symbol;
    let initialCurrency;
    if (isPriceString) {
        symbol = price === null || price === void 0 ? void 0 : price.split(' ')[0];
    }
    else {
        symbol = '';
    }
    initialCurrency = symbol ? constants_1.currencies === null || constants_1.currencies === void 0 ? void 0 : constants_1.currencies[symbol] : price.currencyCode;
    const currency = initialCurrency || null;
    const viewItemEventValue = isPriceString ? (0, exports.convertStringPriceToNumber)(price) : null;
    const viewItemValue = viewItemEventValue > 0 ? viewItemEventValue : null;
    const value = isViewItemEvent
        ? viewItemValue
        : itemsArray.reduce((acc, curr) => {
            var _a, _b, _c;
            const amount = (curr === null || curr === void 0 ? void 0 : curr.quantity) || (curr === null || curr === void 0 ? void 0 : curr.amount) || 1;
            let parsedPrice = 0;
            if ((_b = (_a = curr === null || curr === void 0 ? void 0 : curr.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.itemPrice) {
                parsedPrice = (_c = curr.prices[0]) === null || _c === void 0 ? void 0 : _c.itemPrice;
            }
            else if (typeof curr.price === 'object' && 'amount' in curr.price) {
                parsedPrice = curr.price.amount;
            }
            else if (isPriceString) {
                parsedPrice = (0, exports.convertStringPriceToNumber)(curr.price);
            }
            const value = +(parsedPrice * amount).toFixed(3);
            return acc + value;
        }, 0) || null;
    return { value, currency };
};
exports.calcCurrencyAndValue = calcCurrencyAndValue;
